import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  connect() {
    this.element[this.identifier] = this;
  }

  close() {
    (this.element as any).remove();
    (this.turboFrameModal as any).src = null;
  }
  
  escClose(event: any) {
    if (event.key === 'Escape') this.close();
  }
  
  get turboFrameModal() {
    return document.querySelector('turbo-frame[id="modal"]');
  }
}