import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  initialize() {
    this.element.setAttribute('data-action', 'click->turbo#click')
  }

  async click(event) {
    event.preventDefault();
    this.url = this.element.getAttribute('href');

    const request = await fetch(this.url, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    })

    Turbo.renderStreamMessage(request.body);
  }
}
