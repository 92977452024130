// Stimulus
//
import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

const application = Application.start();

// 3rd Party Plugins
//
import { Alert, Autosave, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from 'tailwindcss-stimulus-components';
import RevealController from 'stimulus-reveal';
import ReadMore from 'stimulus-read-more';

registerControllers(application, import.meta.glob('./**/*_controller.(js|ts|jsx|tsx)', { eager: true }))
registerControllers(application, import.meta.glob('../../components/**/*_controller.(js|ts|jsx|tsx)'), { eager: true });

application.register('read-more', ReadMore);
application.register("reveal", RevealController);

application.register('alert', Alert);
application.register('autosave', Autosave);
application.register('dropdown', Dropdown);
application.register('modal', Modal);
application.register('tabs', Tabs);
application.register('popover', Popover);
application.register('toggle', Toggle);
application.register('slideover', Slideover);