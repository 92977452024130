// Core
// 
import { Controller } from '@hotwired/stimulus';

// Plugins
// 
// import Dailychart from "dailychart";

export default class extends Controller {
  static targets = ['sparkline'];

  declare sparklineTarget: any;

  async connect() {
    // Dailychart.create(this.sparklineTarget, {
    //   lineWidth: 3,
    //   colorPositive: '#047857',
    //   colorNegative: '#B91C1C',
    //   fillPositive: '#ECFDF5',
    //   fillNegative: '#FEF2F2',
    //   closeWidth: 'red',
    //   closeColor: 'blue',
    // });
  }
}