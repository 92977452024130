import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    // const cta: any = this.element.querySelector('[data-turbo-frame]');
    //
    // if (cta) {
    //   cta.addEventListener('click', (event: CustomEvent) => {
    //     const target: HTMLElement = event.currentTarget as any;
    //     const currentHTML: any = target.innerHTML;
    //
    //     this.element.classList.add('loading');
    //
    //     target.classList.add('loading');
    //     target.innerText = 'Loading...';
    //
    //     // this.listen(cta, currentHTML);
    //   })
    // }
  }

  listen(target: any, html: any) {
    // document.addEventListener('turbo:frame-render', () => { console.log('turbo:frame-render') });
    // document.addEventListener('turbo:frame-load', () => {
      // this.element.classList.remove('loading');
      // target.innerHTML = html;

      // removeEventListener('click', target);
    // });
  }
}