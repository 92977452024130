// Core
//
import { Controller } from '@hotwired/stimulus';

// Plugins
//
// import Toastify from 'toastify-js';

export default class extends Controller {
  static targets = ['source'];

  initialize() { }

  connect() {
    this.element.classList.add(`plugins:clipboard:${ document.queryCommandSupported('copy') ? 'enabled' : 'disabled' }`);
  }

  public copy(event: MouseEvent) {
    event.preventDefault();

    const isInput: any = (element: any) => /^(?:input|select|textarea)$/i.test(element.nodeName);

    // @ts-ignore
    if (isInput(this.sourceTarget)) {
      // @ts-ignore
      this.sourceTarget.select();
      this.executeCopy();
    } else {

      // Create a new 'textarea' element which is needed in order to .select()
      //
      const textarea = document.createElement('textarea');

      // Add the innerHTML of the sourceTarget as it's value
      //
      // @ts-ignore
      textarea.value = this.sourceTarget.innerHTML;

      // Make the element available in the DOM
      //
      document.body.appendChild(textarea);

      // Select the textarea's value and execute the 'copy' command
      //
      textarea.select();
      this.executeCopy();

      // We're done, remove the element.
      //
      document.body.removeChild(textarea);
    }
  }

  private executeCopy() {
    // const toast: any = (text: string) => Toastify({
    //   text: text,
    //   duration: 3000,
    //   newWindow: true,
    //   gravity: 'top',
    //   position: 'left'
    // });

    if (document.execCommand('copy')) {
      // toast('Copied!').showToast();
    } else {
      // toast('There was a problem copying the text. Please try again').showToast();
    }
  }

  disconnect() { }
}