import { Controller } from '@hotwired/stimulus';

import Trix from 'trix';
import '@rails/actiontext';

export default class extends Controller {
  static get targets() {
    return [ "editor", "imageFields", "altField" ]
  }
  
  declare editorTarget;
  declare imageFields;
  declare altField;

  connect() {
    this.element[this.identifier] = this
        
    Trix.config.blockAttributes.paragraph = { tagName: 'p', terminal: true, breakOnReturn: true, group: false  }
    Trix.config.blockAttributes.heading2 = { tagName: 'h2', terminal: true, breakOnReturn: true, group: false  }
    Trix.config.blockAttributes.heading3 = { tagName: 'h3', terminal: true, breakOnReturn: true, group: false  }
    Trix.config.blockAttributes.heading4 = { tagName: 'h4', terminal: true, breakOnReturn: true, group: false  }
    Trix.config.blockAttributes.underline = { tagName: 'u', terminal: true, breakOnReturn: true, group: false  }

    this.editorTarget.addEventListener("trix-selection-change", function(event) {
      if (this.mutableImageAttachment) {
        this.imageFieldsTarget.classList.remove("hidden")
        let position = this.mutableImageAttachment.querySelector("img").offsetTop + this.mutableImageAttachment.querySelector("img").offsetHeight
        this.imageFieldsTarget.style.top = `${position}px`
        this.altFieldTarget.value = this.currentAltText
      } else {
        this.imageFieldsTarget.classList.add("hidden")
      }
    }.bind(this))
  }
  
  insertEmbeddable(html) {
    // @ts-ignore
    let embeddable = new Trix.Attachment({
      content: html, 
      contentType: "application/vnd+spina.embed+html"})
      
    this.editor.insertAttachment(embeddable)
  }
  
  preventSubmission(event) {
    if (event.key === 'Enter') event.preventDefault() // Prevent form submit from alt text fields
  }
  
  insertAttachment(event) {
    let attachment = new Trix.Attachment({content: `<span class="trix-attachment-spina-image" data-label="Alt text">
      <img src="${event.detail.embeddedUrl}" />
    </span>`, contentType: "Spina::Image"})
    this.editor.insertAttachment(attachment)
  }
  
  setAltText(event) {
    let alt = event.currentTarget.value
    let altLabel = alt
    if (altLabel.trim().length == 0) altLabel = "Alt text" // Fallback
    let content = this.trixAttachment.getContent()
    
    // Set span data-alt inside Trix attachment
    // @ts-ignore
    this.mutableImageAttachment.firstElementChild.dataset.label = altLabel
    
    // Change content
    let fragment = this.fragmentFromHTML(content)
    // @ts-ignore
    fragment.firstElementChild.dataset.label = altLabel
    fragment.querySelector('img').alt = alt
    let div = document.createElement('div')
    div.appendChild(fragment)
    
    this.trixAttachment.setAttributes({content: div.innerHTML})
  }

  preventDefault(event) {
    event.preventDefault()
  }
  
  getTrixAttachment(id) {
    let attachments = this.attachmentManager.getAttachments()
    return attachments.find(attachment => attachment.id == id).attachment
  }
  
  fragmentFromHTML(html) {
    let range = document.createRange()
    range.selectNodeContents(document.body)
    return range.createContextualFragment(html)
  }
  
  get currentAltText() {
    let fragment = this.fragmentFromHTML(this.trixAttachment.getContent())
    return fragment.querySelector('img').alt
  }
  
  get trixAttachment() {
    // @ts-ignore
    return this.getTrixAttachment(this.mutableImageAttachment.dataset.trixId)
  }
  
  get mutableImageAttachment() {
    return this.element.querySelector(`figure[data-trix-mutable][data-trix-content-type="Spina::Image"]`)
  }
  
  get attachmentManager() {
    return this.editorTarget.editorController.attachmentManager
  }
  
  get editor() {
    return this.editorTarget.editor
  }
}