// Core
//
import '@hotwired/turbo-rails';

import '~/channels/index.js';
import '~/controllers/index.js';

// Plugins
//
import LazyLoad from 'vanilla-lazyload';

import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

import tippy, { delegate } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/translucent.css';
import 'tippy.js/animations/scale.css';

import Chartkick from 'chartkick';
import 'chartkick/chart.js';

import 'dashjs';

import * as ActiveStorage from '@rails/activestorage';
ActiveStorage.start()

document.addEventListener('turbo:load', (event) => {
  new LazyLoad();

  const galleryElement = document.getElementById('gallery');

  if (galleryElement) {
    new Viewer(galleryElement);
  }

  const videoElements = document.querySelectorAll("[data-dashjs-player='']");

  if (videoElements.length > 0) {
    videoElements.forEach((videoElement) => {
      dashjs.MediaPlayer().create().initialize(videoElement, videoElement.src, false);
    });
  }

  // document.querySelectorAll('.onChangeSubmit').forEach((form) => {
  //   console.log('Found' + form + '. Listening for events;');
  //
  //   form.addEventListener('change', (event) => {
  //     console.log('A value changed, submitting..');
  //
  //     form.requestSubmit()
  //   });
  // });

  tippy.setDefaultProps({
    animation: 'scale',
    duration: 250,
    arrow: true,
    theme: 'translucent'
  });

  tippy('[data-tippy-content]');
  delegate('body', { target: '[data-tippy-content]' });

  tippy(document.querySelectorAll('[data-template]'), {
    interactive: true,
    placement: 'left',
    animation: 'scale',
    theme: 'menu',

    content(reference) {
      try { reference.preventDefault(); } catch(e) { }

      const template = document.getElementById(reference.getAttribute('data-template'));
            // container = document.createElement('div'),
            // node = document.importNode(template.content, true);

      // container.appendChild(node);

      // return container;
      try {
        return template.innerHTML;
      } catch(e) {
        console.warn(e)
      }
      // const template = document.getElementById(reference.getAttribute('data-template')),
      //       container = document.createElement('div'),
      //       node = document.importNode(template.content, true);

      // container.appendChild(node);

      // return container;
    }
  });

  try {
    const scrollItems = [...document.querySelectorAll('-scroll.--item')];

    let options = {
      rootMargin: '0px',
      threshold: 0.25
    };

    const callback = (entries, observer) => {
      entries.forEach(entry => {
        const { target } = entry;

        if (entry.intersectionRatio >= 0.25) {
          target.classList.add('is-visible');
        } else {
          target.classList.remove('is-visible');
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    scrollItems.forEach((section, index) => {
      observer.observe(section);
    });

    const scrollToItems = [...document.querySelectorAll('.scroll-to')];

    scrollToItems.forEach((section, index) => {
      section.scrollLeft += document.getElementById(section.dataset.scrollToTarget).offsetLeft;
    });
  } catch(e) {
    
  }

  navigator.serviceWorker.register('/service-worker.js').then(registration => {
    console.log('ServiceWorker registered: ', registration);

    var serviceWorker;
    if (registration.installing) {
      serviceWorker = registration.installing;
      console.log('Service worker installing.');
    } else if (registration.waiting) {
      serviceWorker = registration.waiting;
      console.log('Service worker installed & waiting.');
    } else if (registration.active) {
      serviceWorker = registration.active;
      console.log('Service worker active.');
    }
  }).catch(registrationError => {
    console.log('Service worker registration failed: ', registrationError);
  });
});