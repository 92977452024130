// Core
//
import { Controller } from '@hotwired/stimulus';

// Plugins
//
import Timer from 'easytimer.js';

export default class extends Controller {
  static targets = ['elapsed', 'countdown'];

  elapsedTarget: any;
  hasElapsedTarget: any;
  countdownTarget: any;

  timer: any;

  initialize() { }

  connect() {
    this.timer = new Timer();

    if (this.hasElapsedTarget) this.startElapsedTimer();
  }

  private startElapsedTimer() {
    this.timer.start({
      precision: 'seconds',
      startValues: {
        seconds: parseInt(this.data.get('seconds'))
      },

      callback: (timer: Timer) => {
        this.elapsedTarget.innerHTML = timer.getTimeValues().toString();
      }
    });
  }

  disconnect() {
    if (this.timer.isRunning()) this.timer.stop();
  }
}