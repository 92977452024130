import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['output', 'input'];

  inputTarget: HTMLInputElement;
  outputTarget: HTMLImageElement;

  connect() { }

  readURL() {
    if (this.inputTarget.files && this.inputTarget.files[0]) {
      const reader: FileReader = new FileReader();

      reader.onload = (_event: any) => {
       (this.outputTarget as any).src = reader.result
     }

     reader.readAsDataURL(this.inputTarget.files[0]);
   }
 }
}