import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];
  static values = {
    key: String
  }

  connect() {
    this.injectCaptchaScriptTag();
    this.setFormId();
    this.updateCaptchaTarget();
  }

  injectCaptchaScriptTag() {
    let js, fjs = document.getElementsByTagName('script')[0];

    if (!document.getElementById('hcaptcha-script')) {
      js = document.createElement('script');
      js.id = 'hcaptcha-script';
      js.src = 'https://js.hcaptcha.com/1/api.js';
      js.async = '';
      js.defer = '';

      fjs.parentNode.insertBefore(js, fjs);
    }
  }

  setFormId() {
    this.element.id = 'captcha';
  }

  updateCaptchaTarget() {
    if (!this.hasButtonTarget) { return }

    this.buttonTarget.classList += ' h-captcha';
    this.buttonTarget.setAttribute('data-callback', 'onCaptchaSubmit');

    // Use `setAttribute` instead of `dataset.siteKey` to ensure the data attribute is camelCase'd.
    //
    this.buttonTarget.setAttribute('data-siteKey', this.keyValue);
  }

  disconnect() {
    document.getElementById('hcaptcha-script').remove();
  }
}