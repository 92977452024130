import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form'];

  connect() { }

  // @public
  search(event) {
    clearTimeout(this.timeout);

    const value = event.currentTarget.value;
    const length = value.length;

    if (length === 0 || length > 2) {
      this.timeout = setTimeout(() => {
        this.submit();
      }, 1000);
    }
  }

  // @public
  filter() { this.submit(); }

  // @private
  submit() {
    this.formTarget.requestSubmit();
  }

  disconnect() { }
}