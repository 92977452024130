// Core
//
import { Controller } from '@hotwired/stimulus';

import * as React from 'react';
import { createRoot } from 'react-dom/client';

// Plugins
//
import CalendarHeatmap from 'react-calendar-heatmap';

export default class extends Controller {
  static targets = ['heatmap'];
  static values = {
    dataset: String,
    starts: String,
    ends: String
  };

  private i18ln: { } = {
    psn: {
      name: 'PlayStation Network',
      color: '#003087'
    },

    xbox: {
      name: 'XBOX Live',
      color: '#52b043'
    },

    steam: {
      name: 'Steam',
      color: '#00adee'
    }
  }

  connect() { }

  async initialize() {
    this.render();
  }

  render() {
    const component: any = <CalendarHeatmap

        gutterSize = { 2 }
        showOutOfRangeDays = { false }
        showWeekdayLabels  = { true }
        startDate          = { new Date(this.startsValue) }
        endDate            = { new Date(this.endsValue) }
        values             = { Object.entries(JSON.parse(this.datasetValue)).map((entry) => { return { date: entry[0], count: entry[1] } }) }

        tooltipDataAttrs = {
          (value: any) => {
            if (!value.count || value.count === 0) {
              return ''
            }

            return { 'data-tippy-content': `${ value.count } Trophies on ${ value.date }`}
          }
        }

        classForValue = { this.colourFromValue }

    />;
    console.log(component);
    // @ts-ignore
    const root = createRoot(this.heatmapTarget);
          root.render(component);
  }

  private colourFromValue(value: any) {
    if (!value) { return 'color-empty' }

    const count: number = value.count;
    let klass: number;

    if (between(count, 0, 0)) {
      klass = 0
    } else if (between(count, 1, 5)) {
      klass = 5
    } else if (between(count, 6, 10)) {
      klass = 10
    } else if (between(count, 11, 15)) {
      klass = 15
    } else if (between(count, 16, 20)) {
      klass = 20
    } else if (between(count, 21, 25)) {
      klass = 25
    } else if (between(count, 26, 30)) {
      klass = 30
    } else if (between(count, 31, 35)) {
      klass = 35
    } else if (between(count, 36, 40)) {
      klass = 40
    } else if (count >= 41) {
      klass = 45
    }

    return `color-scale-${ klass } count-${ count }`;
  }

  disconnect() { }
}

function between(x: number, min: number, max: number) {
  return x >= min && x <= max
}