import { Controller } from "@hotwired/stimulus";

// import { addToTabOrder, cancel, delay, nextFrame, removeFromTabOrder, wrapAroundAccess } from "helpers"

export default class extends Controller {
  static targets = [ "item", "link", "input" ]
  // static targets = ['style', 'url', 'type'];

  itemTarget: Element;
  itemTargets: Element[];
  hasItemTarget: boolean;

  linkTarget: Element;
  linkTargets: Element[];
  hasLinkTarget: boolean;

  inputTarget: Element;
  inputTargets: Element[];
  hasInputTarget: boolean;

  static values = { lockOnSelection: Boolean }
  get classList() {
    return this.element.classList
  }

  dispatch(eventName, { target = this.element, detail = {}, bubbles = true, cancelable = true } = {}) {
    const type = `${this.identifier}:${eventName}`
    const event = new CustomEvent(type, { detail, bubbles, cancelable })
    target.dispatchEvent(event)
    return event
  }

  observeMutations(callback, target = this.element, options = { childList: true, subtree: true }) {
    const observer = new MutationObserver(mutations => {
      observer.disconnect()
      Promise.resolve().then(start)
      callback.call(this, mutations)
    })
    function start() {
      if (target.isConnected) observer.observe(target, options)
    }
    start()
  }

  get pageIsTurboPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview")
  }
  initialize() {
    if (this.hasLinkTarget) (this.linkTarget as any).hidden = true
    // @ts-ignore
    this.observeMutations(this.removeTabstops, { childList: true })
  }

  connect() {
    this.summaryElement?.setAttribute("aria-haspopup", "menu")
    this.update()
  }

  disconnect() {
    this.close()
  }

  // Actions

  async update() {
    this.updateExpanded()

    if (!(this.element as any).open) {
      return
    }

    if (this.hasLinkTarget) {
      (this.linkTarget as any).click()
    }

    if (this.frameElement) {
      if ((this.frameElement as any).disabled) {
        this.close()
        return
      }

      await (this.frameElement as any).loaded
    }

    await Promise.all(loadFrameElementsWithin(this.element))
    this.focusInitial()
    this.removeTabstops()
  }

  // navigate(event) {
  //   switch (event.key) {
  //     case "ArrowUp":
  //       if (this.element.open && isActive(this.summaryElement)) {
  //         this.summaryElement.click()
  //         cancel(event)
  //       } else {
  //         this.focusPrevious()
  //         cancel(event)
  //       }
  //       break
  //     case "ArrowLeft":
  //       if (isInput(event.target)) break
  //       cancel(event)
  //       this.focusPrevious()
  //       break
  //     case "ArrowDown":
  //       if (!this.element.open && isActive(this.summaryElement)) {
  //         this.summaryElement.click()
  //         cancel(event)
  //       } else {
  //         this.focusNext()
  //         cancel(event)
  //       }
  //       break
  //     case "ArrowRight":
  //       if (isInput(event.target)) break
  //       cancel(event)
  //       this.focusNext()
  //       break
  //     case "Escape":
  //       cancel(event)
  //       this.closeAndRestoreFocus()
  //       break
  //     case "Enter":
  //       if (!(this.activeItem || isActive(this.summaryElement))) this.activateInitial()
  //       break
  //     case "Control":
  //       cancel(event)
  //       if (this.hasInputTarget) { this.inputTarget.blur() }
  //       break
  //   }
  // }

  closeOnClickOutside({ target }) {
    
    // Ensure we don't close the menu if the click is on the menu itself
    // 
    if (this.element.contains(target)) return
    // if (this.forceMenuOpen) return

    this.close()
  }

  closeOnFocusOutside({ target }) {
    if (!(this.element as any).open) return
    if (this.element.contains(target)) return
    if (target.matches("main")) return
    // if (this.forceMenuOpen) return

    this.close()
  }

  // closeAndRestoreFocus() {
  //   this.close()
  //   this.summaryElement.focus()
  // }

  // toggleSummaryTabstop() {
  //   const summary = this.element.querySelector("summary")

  //   this.element.open ? removeFromTabOrder(summary) : addToTabOrder(summary)
  // }

  // async willFocusByMouse() {
  //   this.focusingByMouse = true
  //   await delay()
  //   this.focusingByMouse = false
  // }

  displayMenu() {
    // @ts-ignore
    if (this.focusingByMouse) return
    (this.element as any).open = true
  }

  close() {
    (this.element as any).open = false
  }

  // // Private
  // //

  updateExpanded() {
    this.summaryElement?.setAttribute("aria-expanded", (this.element as any).open)
  }

  removeTabstops() {
    this.itemTargets.forEach(target => target.setAttribute("tabindex", "-1"))
  }

  // async activateInitial() {
  //   await this.focusInitial()
  //   await nextFrame()
  //   this.initialItem?.click()
  // }

  async focusInitial() {
    // await nextFrame()

    if (this.hasInputTarget) {
      this.focusExclusively(this.inputTarget)
    } else if (this.hasItems) {
      this.focusExclusively(this.initialItem)
    }
  }

  // async focusPrevious() {
  //   await nextFrame()
  //   this.focusExclusively(this.getItemInDirection(-1))
  // }

  // async focusNext() {
  //   await nextFrame()
  //   this.focusExclusively(this.getItemInDirection(+1))
  // }

  // getItemInDirection(direction) {
  //   const index = this.items.indexOf(document.activeElement) + direction

  //   return wrapAroundAccess(this.items, index)
  // }

  // get forceMenuOpen() {
  //   return this.lockOnSelectionValue && (
  //     this.element.querySelector(":checked") ||
  //     this.element.contains(document.activeElement)
  //   )
  // }

  focusExclusively(element) {
    if (element) {
      this.removeTabstops()
      element.focus()
      element.removeAttribute("tabindex")
    }
  }

  get items() {
    return this.itemTargets.filter(isVisible)
  }

  get hasItems() {
    return this.itemTargets.some(isVisible)
  }

  // get activeItem() {
  //   return this.itemTargets.find(isActive)
  // }

  get initialItem() {
    return this.itemTargets.find(wantsFocus) || this.items[0]
  }

  get summaryElement() {
    return this.element.querySelector("summary")
  }

  get frameElement() {
    const id = this.hasLinkTarget && this.linkTarget.getAttribute("data-turbo-frame")
    return id && document.getElementById(id)
  }
}

function loadFrameElementsWithin(element) {
  const frames = [ ...element.querySelectorAll("turbo-frame[src]:not([disabled])") ]

  return frames.map(frame => frame.loaded)
}

// function isInput(element) {
//   return element.tagName == "INPUT"
// }

function wantsFocus(element) {
  return element.getAttribute("autofocus")
}

// function isActive(element) {
//   return element == document.activeElement
// }

function isVisible(element) {
  return element.offsetParent != null
}
