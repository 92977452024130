import { Controller } from '@hotwired/stimulus';
import confetti from 'canvas-confetti';

export default class extends Controller {
  confettiInstance: any;
  
  connect() {
    // this.createCanvas();
    // this.createConfetti();
    // this.removeCanvas();
  }

  createConfetti() {
    const that = this;

    this.confettiInstance = confetti.create(this.canvas);
    
    const duration: number = 15 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults: any = {
      startVelocity: 30,
      spread: 360,
      ticks: 60,
      zIndex: 0
    }

    const interval = setInterval(function() {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);
      const y = (Math.random() - 0.2)

      confetti(Object.assign({}, defaults, {
        particleCount, origin: {
          x: that.randomInRange(0.1, 0.3),
          y: y
        }
      }));

      confetti(Object.assign({}, defaults, {  
        particleCount,
        origin: {
          x: that.randomInRange(0.7, 0.9),
          y: y 
        }
      }));

    }, 250);
  }
 
  randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  createCanvas() {
    this.element.insertAdjacentHTML('beforeEnd', `<canvas id="confetti" class="absolute top-0 w-full h-full pointer-events-none z-50"></canvas>`)
  }
  
  removeCanvas() {
    this.canvas.remove();
  }
  
  get canvas() {
    return document.querySelector("#confetti");
  }
}